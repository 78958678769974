import resume from "../assets/files/jorden-gerovac-professional-resume.pdf";

function Experience() {
  return (
    <div>
      <h6 className="back-home-button">
        <a className="partial-underline-sm" href="/">
          Home
        </a>
      </h6>
      <div className="screen-swipe"></div>
      <div className="experience-page-container">
        <h1 className="fade-in">Experience</h1>
        <div className="experience-content">
          <div className="row gx-5 mb-5">
            <div className="col-lg-4 fade-in">
              <div className="experience-section">
                <h5 className="subtle">Work Experience</h5>
              </div>
            </div>
            <div className="col-lg-8 fade-in">
              <div className="experience-container">
                <div className="experience-title">
                  <h5>Software Developer / Senior Consultant</h5>
                  <h5 className="subtle">Veriday</h5>
                  <p className="subtle"> Jul 2020 – Present</p>
                </div>
                <div className="experience-description">
                  <ul>
                    <li className="subtle">
                      Developed a business performance dashboard in Liferay to
                      display historical sales data of products sold online and
                      in-store over custom date ranges using React and Spring
                      Boot; allowed vendors to make promotional decisions based
                      on daily insights
                    </li>
                    <li className="subtle">
                      Developed a product data transformation portal using
                      Micronaut and React where vendors were able to upload a
                      .csv or .xlsx spreadsheet and data was transformed and
                      unified between business data stored in GCP (Google Cloud
                      Platform) and the uploaded third party vendor data;
                      improved workflow efficiency by greatly reducing time and
                      resources needed to perform daily data reviews
                    </li>
                    <li className="subtle">
                      Led the implementation of a consolidated project portal
                      application with Jira integration and Keycloak
                      authentication using React and Spring Boot; allowed for
                      administrators to view and maintain the consolidation of
                      all vendor projects and permissions dynamically
                    </li>
                    <li className="subtle">
                      Built a JSON parsing program in Java that used scraped
                      data to calculate scores based on negative and threatening
                      language using the Apache OpenNLP natural language
                      library; used this data to uncover and map illicit
                      activity in local communities using ArcGIS
                    </li>
                  </ul>
                </div>
                <div className="experience-skills">
                  <div>Java</div>
                  <div>JavaScript</div>
                  <div>MySQL</div>
                  <div>React</div>
                  <div>Redux</div>
                  <div>Vue</div>
                  <div>Spring Boot</div>
                  <div>Docker</div>
                  <div>Liferay</div>
                  <div>GraphQL</div>
                </div>
              </div>
              <div className="experience-container">
                <div className="experience-title">
                  <h5>Software Developer</h5>
                  <h5 className="subtle">MoviWear</h5>
                  <p className="subtle"> May 2019 – Aug 2019</p>
                </div>
                <div className="experience-description">
                  <ul>
                    <li className="subtle">
                      Developed VoIP and virtual assistant applications for
                      Android wearables using Android Studio; created custom
                      voice commands based on feedback from the families of
                      seniors actively using the SOS smart watch
                    </li>
                    <li className="subtle">
                      Updated and modernized consumer mobile application
                      frontend using React and developed .NET APIs for seamless
                      integration with backend services like Stripe; deployed
                      using DigitalOcean droplets
                    </li>
                    <li className="subtle">
                      Responsible for rebranding and launching products in North
                      and South America along with a corresponding product
                      website and marketing campaign; integrated the SOS smart
                      watch and alert button into international markets
                    </li>
                  </ul>
                </div>
                <div className="experience-skills">
                  <div>C#</div>
                  <div>Java</div>
                  <div>Kotlin</div>
                  <div>JavaScript</div>
                  <div>MySQL</div>
                  <div>.NET</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-lg-4 fade-in">
              <div className="experience-section">
                <h5 className="subtle">Education</h5>
              </div>
            </div>
            <div className="col-lg-8 fade-in">
              <div className="experience-container">
                <div className="experience-title">
                  <h5>Computer Science (B.Sc.)</h5>
                  <h5 className="subtle">
                    Toronto Metropolitan University (Ryerson University)
                  </h5>
                  <p className="subtle">2017 - 2020</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-5 my-4">
            <div className="col-lg-4">
              <h5 className="back-home-button">
                <a
                  className="partial-underline-sm"
                  href={resume}
                  target="_blank"
                  rel="noreferrer"
                >
                  View full resume <span>&#187;</span>
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
