import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <div className="screen-swipe"></div>
      <div className="home-page-container">
        <div className="row gx-5">
          <div className="col-md-6 mb-4 fade-in">
            <h1>Jorden Gerovac</h1>
            <h5>Software Developer</h5>
            <p className="subtle">
              I help turn ideas into seamless digital solutions.
            </p>
            <div className="social-icons">
              <a
                href="https://github.com/jordengerovac"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href="https://linkedin.com/in/jordengerovac"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="mailto:jordengerovac@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>
          <div className="col-md-6 fade-in">
            <div className="navigation">
              <ul>
                <li className="subtle">
                  <Link to="/about" className="partial-underline-lg">
                    About
                  </Link>
                </li>
                <li className="subtle">
                  <Link to="/experience" className="partial-underline-lg">
                    Experience
                  </Link>
                </li>
                <li className="subtle">
                  <Link to="/projects" className="partial-underline-lg">
                    Projects
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
