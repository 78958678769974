import tabsolute from "../assets/images/tabsolute.jpg";
import bandmates from "../assets/images/bandmates.png";
import statcast from "../assets/images/statcast.png";
import algorithms from "../assets/images/algorithms.png";
import fantasy from "../assets/images/fantasy.png";
import rush from "../assets/images/rush.jpg";

function Projects() {
  return (
    <div>
      <h6 className="back-home-button">
        <a className="partial-underline-sm" href="/">
          Home
        </a>
      </h6>
      <div className="screen-swipe"></div>
      <div className="projects-page-container">
        <h1 className="fade-in">Projects</h1>
        <div className="projects-content">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 mb-4">
              <a
                href="https://github.com/jordengerovac/bandmates-app-backend"
                target="_blank"
                rel="noreferrer"
              >
                <div className="project-card">
                  <img src={bandmates} alt="Bandmates login page" />
                  <h5>
                    Bandmates <span>&#187;</span>
                  </h5>
                  <p className="subtle">
                    A Spring Boot application backend with JWT authentication,
                    and a React/Redux frontend, that connects musicians and
                    music lovers through their common music taste using the
                    Spotify API.
                  </p>
                  <div className="experience-skills">
                    <div>Java</div>
                    <div>JavaScript</div>
                    <div>MySQL</div>
                    <div>Spring Boot</div>
                    <div>React</div>
                    <div>Redux</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <a
                href="https://github.com/jordengerovac/python-statcast"
                target="_blank"
                rel="noreferrer"
              >
                <div className="project-card">
                  <img src={statcast} alt="Homerun hotzone visualization" />
                  <h5>
                    Statcast AI Project <span>&#187;</span>
                  </h5>
                  <p className="subtle">
                    Displaying Aaron Judge's homerun hot zones using Statcast AI
                    data and python libraries such as matplotlib and pandas.
                  </p>
                  <div className="experience-skills">
                    <div>Python</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <a
                href="https://chromewebstore.google.com/detail/tabsolute/bajoceadbolpcgfjbpknkpkdodnfehbn"
                target="_blank"
                rel="noreferrer"
              >
                <div className="project-card">
                  <img
                    src={tabsolute}
                    alt="Tabsolute browser extension dashboard"
                  />
                  <h5>
                    Tabsolute Browser Extension <span>&#187;</span>
                  </h5>
                  <p className="subtle">
                    A customizable new tab browser extension built with React
                    and Redux. A solution to your boring browser tabs, a user is
                    able to alternate between different views and add a variety
                    of widgets to their view.
                  </p>
                  <div className="experience-skills">
                    <div>JavaScript</div>
                    <div>React</div>
                    <div>Redux</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <a
                href="https://github.com/jordengerovac/fantasy-helper-app-backend"
                target="_blank"
                rel="noreferrer"
              >
                <div className="project-card">
                  <img src={fantasy} alt="Fantasy helper iOS app" />
                  <h5>
                    Fantasy Helper App <span>&#187;</span>
                  </h5>
                  <p className="subtle">
                    Phoenix app with GraphQL and ChatGPT integration, and iOS
                    app, for helping users decide who to draft for their fantasy
                    baseball team.
                  </p>
                  <div className="experience-skills">
                    <div>Elixir</div>
                    <div>Swift</div>
                    <div>Phoenix</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <a
                href="https://github.com/jordengerovac/the-rush-challenge"
                target="_blank"
                rel="noreferrer"
              >
                <div className="project-card">
                  <img src={rush} alt="The Rush challenge dashboard" />
                  <h5>
                    "The Rush" Challenge <span>&#187;</span>
                  </h5>
                  <p className="subtle">
                    The goal of this coding challenge was to build a scalable
                    app that allows users to upload spreadsheet data containing
                    NFL players and their rushing stats, and then view the
                    uploaded data in a customizable table. Built using the MERN
                    stack.
                  </p>
                  <div className="experience-skills">
                    <div>JavaScript</div>
                    <div>React</div>
                    <div>Node</div>
                    <div>Express</div>
                    <div>MongoDB</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <a
                href="https://github.com/jordengerovac/algorithm-visualizations"
                target="_blank"
                rel="noreferrer"
              >
                <div className="project-card">
                  <img src={algorithms} alt="A-star algorithm visualization" />
                  <h5>
                    Algorithm Visualizations <span>&#187;</span>
                  </h5>
                  <p className="subtle">
                    Visualizations of a few different algorithms using Python
                    and Tkinter.
                  </p>
                  <div className="experience-skills">
                    <div>Python</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="row gx-5 my-4">
            <div className="col-lg-12 fade-in">
              <h5 className="back-home-button">
                <a
                  className="partial-underline-sm"
                  href="https://github.com/jordengerovac"
                  target="_blank"
                  rel="noreferrer"
                >
                  View all projects <span>&#187;</span>
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
